/** @jsx jsx */
import { jsx } from "@emotion/core";

function SiteFooterText(props) {
  return (
    <p css={theme => ({
      fontSize: '0.9rem',
      color: theme.colors.white,
      marginTop: '0.25rem',
      marginBottom: '0.25rem',

      [theme.mq('sm')]: {
        fontSize: '1rem'
      }
    })}>
      {props.renderText()}
    </p>
  );
}

export default SiteFooterText;
