/** @jsx jsx */
import { jsx } from "@emotion/core";
import { CardColumns } from 'react-bootstrap';

import PoliticiansSocialPostsTwitter from './PoliticiansSocialPostsTwitter';

function PoliticiansSocialPosts( {socialData} ) {
  if (socialData.twitter.length !== 0) {
    return (
      <section>
        <hr />
        
        <h3>Social Media Posts</h3>
        
        <CardColumns>
          {socialData.twitter.map(tweet => (
            <PoliticiansSocialPostsTwitter tweet={tweet} />
          ))}
        </CardColumns>
      </section>
    );
  } else {
    return (null);
  }
}

export default PoliticiansSocialPosts;
