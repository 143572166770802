/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ThemeProvider } from 'emotion-theming';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// global components
import SiteHeader from '../components/global/SiteHeader';
import SiteFooter from '../components/global/SiteFooter';

// components
import Home from './Home';
import Politicians from '../components/politicians/Politicians';

// emotion theme
import theme from './theme';

function App() {
  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}>
      <ThemeProvider theme={theme}>
        <Router className="content-Router">
          <div css={{
            flex: '1 0 auto'
          }}>
            <SiteHeader />
            <Route exact path="/" component={Home} />
            <Route path="/politicians" component={Politicians} />
          </div>
          <SiteFooter />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
