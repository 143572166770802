/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from 'react';
import axios from 'axios';

import api from '../../app/api';
import PoliticiansSocialPosts from './PoliticiansSocialPosts';
import PoliticiansNews from './PoliticiansNews';
import PoliticiansVotes from './PoliticiansVotes';
import PoliticiansSocialProfileIcons from './PoliticiansSocialProfileIcons';
import PoliticiansAbgeordnetenwatchGeneral from './PoliticiansAbgeordnetenwatchGeneral';

function PoliticiansPage( {match} ) {
  const politicianId = match.params.politicianId;
  const [politician, setPolitician] = useState({
    socialAccounts: {}
  });
  const [politicianSocial, setPoliticianSocial] = useState({
    twitter: [],
    facebook: []
  });
  const [politicianNews, setPoliticianNews] = useState([]);
  const [politicianVotes, setPoliticianVotes] = useState({
    data: []
  });

  useEffect(() => {
    const fetchPoliticians = async () => {
      const result = await axios(
        api.clientUri + '/politicians/' + politicianId,
      );
      setPolitician(result.data);
    };
    fetchPoliticians();

    const fetchPoliticiansSocial = async () => {
      const result = await axios(
        api.clientUri + '/politicians/' + politicianId + '/social',
      );
      setPoliticianSocial(result.data);
    };
    fetchPoliticiansSocial();

    const fetchPoliticiansNews = async () => {
      const result = await axios(
        api.clientUri + '/politicians/' + politicianId + '/news',
      );
      setPoliticianNews(result.data);
    };
    fetchPoliticiansNews();

    const fetchPoliticiansVotes = async () => {
      const result = await axios(
        api.clientUri + '/politicians/' + politicianId + '/votes',
      );
      setPoliticianVotes(result.data);
    };
    fetchPoliticiansVotes();
  }, [politicianId]);

  return (
    <article>
      <header>
        <img alt={`Foto von ${politician.firstName} ${politician.lastName}`} src={politician.imageUrl} class="img-thumbnail float-right" style={{ maxHeight: '10rem' }} />
        <h2>{politician.firstName} {politician.lastName}</h2>
        {politician.extract}<br />
        <p><small>Quelle: <a href={`https://de.wikipedia.org/wiki/${encodeURIComponent(politician.wikipediaPageName)}`}>Wikipedia</a></small></p>
        
        <PoliticiansAbgeordnetenwatchGeneral politicianData={politician} />
        
        { (politician.website) &&
          <p>Website: <a href={ politician.website } target="_blank" rel="noopener noreferrer">
            { politician.website }
          </a></p>
        }
        <PoliticiansSocialProfileIcons socialAccounts={politician.socialAccounts} />
      </header>

      <PoliticiansSocialPosts socialData={politicianSocial} />

      <PoliticiansNews newsData={politicianNews} />

      <PoliticiansVotes votesData={politicianVotes} />
    </article>
  );
}

export default PoliticiansPage;
