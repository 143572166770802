/** @jsx jsx */
import { jsx } from "@emotion/core";
import {Link} from 'react-router-dom';

function SiteHeader(props) {
  return (
    <header className="SiteHeader" css={theme => ({
        background: theme.colors.grayLight,
        textAlign: 'center',
        marginBottom: theme.margins.componentDefault 
      })}>
      <div className="container">
        <Link to="/"><h1>Politicians Social</h1></Link>
      </div>
    </header>
  );
}

export default SiteHeader;
