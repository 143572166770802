/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from 'react';
import axios from 'axios';

import api from '../../app/api';
import PoliticiansOverviewCardColumns from './PoliticiansOverviewCardColumns';

function PoliticiansOverview(props) {
  const [politicians, setPoliticians] = useState([]);

  useEffect(() => {
    const fetchPoliticians = async () => {
      const result = await axios(
        api.clientUri + '/politicians',
      );
      setPoliticians(result.data);
    };
    fetchPoliticians();
  }, []);

  return (
    <PoliticiansOverviewCardColumns politicians={politicians} />
  );
}

export default PoliticiansOverview;
