/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Card, Button } from 'react-bootstrap';
import Linkify from 'react-linkify';
import moment from "moment";
import 'moment/locale/de';

function PoliticiansSocialPostsCard( {id, date, platformIcon, imageSource, imageLink, bodyText, sourceLink, sourceName, buttonText } ) {
  return (
    <Card style={{ maxWidth: '30rem' }} key={id} x-date={moment( date ).format("X")}>
      <Card.Header style={{fontSize: '1rem'}}>
        { platformIcon }  {`${moment( date ).locale('de').format('dddd, Do MMMM YYYY HH.mm')} Uhr`}
      </Card.Header>
      { (imageSource) &&
      <a href={ imageLink } target="_blank" rel="noopener noreferrer">
        <Card.Img variant="top" src={ imageSource } />
      </a>
      }
      <Card.Body>
        <Card.Text style={{fontSize: '1rem'}}>
          <Linkify>{ bodyText }</Linkify>
        </Card.Text>
          <Button variant="primary" href={ sourceLink }>{ buttonText }</Button>
      </Card.Body>
      <Card.Footer className="text-muted" style={{fontSize: '1rem'}}>
        Veröffentlicht auf <a href={ sourceLink }>{ sourceName }</a>
      </Card.Footer>
    </Card>
  );
}

export default PoliticiansSocialPostsCard;
