/** @jsx jsx */
import { jsx } from "@emotion/core";
import { CardColumns } from 'react-bootstrap';

import PoliticiansVotesCard from './PoliticiansVotesCard';

function PoliticiansVotes( {votesData} ) {
  if (votesData.data.length !== 0) {
    return (
      <section>
        <hr />
        
        <h3>Abstimmungsergebnisse</h3>

        <CardColumns>
          {votesData.data.map(vote => (
              <PoliticiansVotesCard vote={vote} />
          ))}
        </CardColumns>
      </section>
    );
  } else {
    return (null);
  }
}

export default PoliticiansVotes;
