/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function PoliticiansVotesCard( { vote } ) {
  return (
    <Card style={{ maxWidth: '30rem' }} key={vote.id}>
      <Card.Header style={{fontSize: '1rem'}}>
        Namentliche Abstimmung
      </Card.Header>
      <Card.Body>
        <h3>{ vote.poll.label }</h3>

        <p style={ {fontSize: '1.25rem' }}>
        { (vote.vote === "no_show") && 
          <span>
            <FontAwesomeIcon icon={ faDotCircle } fixedWidth />
            Nicht abgestimmt
          </span>
        }
        { (vote.vote === "yes") && 
          <span>
            <FontAwesomeIcon icon={ faCheckCircle } fixedWidth color="green" />
            Angenommen
          </span>
        }
        { (vote.vote === "no") && 
          <span>
            <FontAwesomeIcon icon={ faTimesCircle } fixedWidth color="red" />
            Abgelehnt
          </span>
        }
        </p>
      </Card.Body>
    </Card>
  );
}

export default PoliticiansVotesCard;
