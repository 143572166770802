/** @jsx jsx */
import { jsx } from "@emotion/core";
import {Link} from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';

function PoliticiansOverviewCard( {politician, cardSingle} ) {
  return (
    <Card style={{ maxWidth: '30rem' }} key={politician._id}>
      <Card.Img variant="top" src={politician.imageUrl} />
      <Card.Body>
        <Card.Title>{politician.firstName} {politician.lastName}</Card.Title>
        <Card.Text style={{fontSize: '1rem'}}>
          {politician.extract}
        </Card.Text>
        { (!cardSingle) &&
          <Link to={`/politicians/${politician._id}`}>
            <Button variant="primary">mehr zur Person</Button>
          </Link>
        }
        
      </Card.Body>
    </Card>
  );
}

export default PoliticiansOverviewCard;
