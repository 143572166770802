/** @jsx jsx */
import { jsx } from "@emotion/core";
import { CardColumns } from 'react-bootstrap';

import PoliticiansNewsCard from './PoliticiansNewsCard';

function PoliticiansNews( {newsData} ) {
  if (newsData.length !== 0) {
    return (
      <section>
        <hr />
        
        <h3>Aktuelle Nachrichten</h3>

        <CardColumns>
          {newsData.map(news => (
              <PoliticiansNewsCard news={news} />
          ))}
        </CardColumns>
      </section>
    );
  } else {
    return (null);
  }
}

export default PoliticiansNews;
