/** @jsx jsx */
import { jsx } from "@emotion/core";

import SiteFooterText from './SiteFooterText';
import SiteFooterLink from './SiteFooterLink';

function SiteFooter() {
  return (
    <footer className="SiteFooter" css={theme => ({
      flexShrink: 0,
      textAlign: 'center',
      backgroundColor: theme.colors.secondary,
      overflow: 'hidden',
      paddingTop: theme.margins.componentDefault,
      paddingBottom: theme.margins.componentDefault,
      marginTop: theme.margins.componentDefault
    })}>
      <div className="container">
        <SiteFooterText renderText={() => ["Politicians Social - ein Projekt im Rahmen der Veranstaltung ", <SiteFooterLink text="Web Architekturen" href="https://www.medieninformatik.th-koeln.de/study/master/moduls/ma_wtw_modul_web-architekturen/" />, " im ", <SiteFooterLink text="Masterstudium der Medieninformatik" href="https://www.medieninformatik.th-koeln.de/study/master/" />, " an der ", <SiteFooterLink text="TH Köln" href="https://www.th-koeln.de/" />]} />
        <SiteFooterText renderText={() => ["© 2020 - ", <SiteFooterLink text="Florian Bräunsbach" href="https://florianbraeunsbach.de/" />]} />
        <SiteFooterText renderText={() => [<SiteFooterLink text="Impressum" href="https://florianbraeunsbach.de/impressum" />, " | ", <SiteFooterLink text="Datenschutz" href="https://florianbraeunsbach.de/datenschutz" />]} />
      </div>
    </footer>
  );
}

export default SiteFooter;
