// emotion theme

// media query funtion
// useage example: [theme.mq('sm')]: { ... }
const mq = n => {
  const bpArray = Object.keys(theme.breakpoints).map(key => [key, theme.breakpoints[key]]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`];
    return acc;
  }, []);

  return result;
};

// theme global variables
const theme = {
  margins: {
    componentDefault: '1rem'
  },
  colors: {
    primary:        '#CF1820',
    secondary:      '#EC6525',
    tertiary:       '#AF368C',
    white:          '#FFFFFF',
    black:          '#000000',
    gray:           '#5f5f5f',
    grayLight:      '#f7f7f9',
    graySemilight:  '#efefef'
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  mq: mq
};

export default theme;
