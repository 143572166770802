/** @jsx jsx */
import { jsx } from "@emotion/core";
import { CardColumns } from 'react-bootstrap';

import PoliticiansOverviewCard from './PoliticiansOverviewCard';

function PoliticiansOverviewCardDeck( {politicians} ) {
  return (
    <CardColumns>
      {politicians.map(politician => (
        <PoliticiansOverviewCard politician={politician} />
      ))}
    </CardColumns>
  );
}

export default PoliticiansOverviewCardDeck;
