/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

import PoliticiansSocialPostsCard from './PoliticiansSocialPostsCard';

function PoliticiansSocialPostsTwitter( {tweet} ) {
  var imgSource;
  var imgLink;

  if (tweet.entities.media) {
    imgSource = tweet.entities.media[0].media_url_https;
    imgLink = tweet.entities.media[0].expanded_url;
  }

  return (
    <PoliticiansSocialPostsCard
      id={tweet.id_str}
      date={tweet.created_at}
      platformIcon={<FontAwesomeIcon icon={ faTwitter } />}
      imageSource={imgSource}
      imageLink={imgLink}
      bodyText={tweet.full_text}
      sourceLink={`https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`}
      sourceName={'Twitter'}
      buttonText={'Tweet ansehen'}
    />
  );
}

export default PoliticiansSocialPostsTwitter;
