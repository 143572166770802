/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

function PoliticiansSocialProfileIcons( {socialAccounts} ) {
  if (socialAccounts) {
    return (
      <p>
        Profile in Sozialen Netzwerken: 
        { (socialAccounts.twitter) &&
          <a href={`https://twitter.com/${socialAccounts.twitter}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={ faTwitter } fixedWidth />
          </a>
        }
        { (socialAccounts.instagram) &&
          <a href={`https://www.instagram.com/${socialAccounts.instagram}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={ faInstagram } fixedWidth />
          </a>
        }
        { (socialAccounts.facebook) &&
          <a href={`https://www.facebook.com/${socialAccounts.facebook}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={ faFacebook } fixedWidth />
          </a>
        }
      </p>
    );
  } else {
    return(null);
  }
}

export default PoliticiansSocialProfileIcons;
