/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import PoliticiansPage from './PoliticiansPage';

function Politicians({ match }) {
  return (
    <Container>
      <Route path={`${match.path}/:politicianId`} component={PoliticiansPage}/>
    </Container>
  );
}

export default Politicians;
