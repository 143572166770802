import React from 'react';

import PoliticiansOverview from '../components/politicians/PoliticiansOverview';

function Home() {
  return (
    <div className="Home container">
      <h2>Übersicht</h2>
      <PoliticiansOverview />
    </div>
  );
}

export default Home;
