/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Card, Button } from 'react-bootstrap';
import moment from "moment";
import 'moment/locale/de';

function PoliticiansNewsCard( { news } ) {
  return (
    <Card style={{ maxWidth: '30rem' }} key={news.id} x-date={moment( news.date ).format("X")}>
      <Card.Header style={{fontSize: '1rem'}}>
        {`${moment( news.date ).locale('de').format('dddd, Do MMMM YYYY HH.mm')} Uhr`}
      </Card.Header>
      <Card.Img variant="top" src={ news.teaserImage.url } alt={ news.teaserImage.title } />
      <Card.Body>
        <Card.Title>{ news.title }</Card.Title>
        <Card.Text style={{fontSize: '1rem'}}>
          { news.teaserText }
        </Card.Text>
          <Button variant="primary" href={ news.url }>Meldung öffnen</Button>
      </Card.Body>
    </Card>
  );
}

export default PoliticiansNewsCard;
