/** @jsx jsx */
import { jsx } from "@emotion/core";

function SiteFooterLink(props) {
  return (
    <a css={theme => ({
      color: theme.colors.white,
      ':hover,:focus': {
        color: theme.colors.graySemilight
      }
    })} href={props.href}>
      {props.text}
    </a>
  );
}

export default SiteFooterLink;
