/** @jsx jsx */
import { jsx } from "@emotion/core";

function PoliticiansAbgeordnetenwatchGeneral( {politicianData} ) {
  if (politicianData.abgeordnetenwatch) {
    return (
      <div>
        { (politicianData.party) &&
          <span><strong>Parteizugehörigkeit:</strong> { politicianData.party }<br /></span>
        }
        { (politicianData.year_of_birth) &&
          <span><strong>Geburtsjahr:</strong> { politicianData.year_of_birth }<br /></span>
        }
        { (politicianData.occupation) &&
          <span><strong>Ausgeübte Tätigkeit:</strong> { politicianData.occupation }<br /></span>
        }
        { (politicianData.education) &&
          <span><strong>Berufliche Qualifikation:</strong> { politicianData.education }<br /></span>
        }
        <p><small>Quelle: <a href={politicianData.abgeordnetenwatch.profilePage}>Abgeordnetenwatch</a></small></p>
      </div>
    );
  } else {
    return (null);
  }
  
}

export default PoliticiansAbgeordnetenwatchGeneral;
